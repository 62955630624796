import React, { useState } from 'react';
import SimpleCard from '../../Cards/SimpleCard/SimpleCard';
import { FaTimes } from 'react-icons/fa';
import './VideoModal.scss';

interface VideoModalProps {
  videoID: string;
  videoTitle: string;
}

const VideoModal: React.FC<VideoModalProps> = ({ videoID, videoTitle }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <SimpleCard
        theme="primary"
        cardHeader={videoTitle}
        cardContent="Click to play video"
        backgroundImage={`https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`}
        onClick={openModal}
      />
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button
              title='close button'
              className="close-button"
              tabIndex={0}
              type='button'
              aria-label={'close button'}
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  closeModal();
                }
              }}
            >
              <FaTimes />
            </button>
            <iframe
              title={videoTitle}
              width="1080"
              height="500"
              src={`https://www.youtube.com/embed/${videoID}?autoplay=1`}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoModal;
