import React from 'react';
import { motion } from 'framer-motion';
import './fullIconBtn.scss';

interface FullIconButtonProps {
    label?: string;
    icon?: React.ReactNode;
    theme?: 'primary' | 'secondary' | 'warning' | 'error' | 'success' | string;
    href?: string;
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
    title?: string;
  }
  
  const FullIconButton: React.FC<FullIconButtonProps> = ({
    label,
    icon,
    theme = 'primary',
    href,
    onClick,
    className = '',
    disabled,
    title,
  }) => {
    return (
      <motion.a
        href={disabled ? undefined : href}
        className={`full-icon-button ${theme} ${className} ${disabled ? 'disabled' : ''}`}
        target={href && !disabled ? "_blank" : undefined}
        rel={href && !disabled ? "noopener noreferrer" : undefined}
        whileHover={!disabled ? { scale: 1.05 } : {}}
        whileTap={!disabled ? { scale: 0.95 } : {}}
        transition={{ type: 'spring', stiffness: 300, damping: 20 }}
        onClick={(e) => {
          if (!disabled && onClick) {
            e.preventDefault();
            onClick();
          }
        }}
        aria-label={label}
        role="button"
        aria-disabled={disabled}
        tabIndex={0}
        title={title}
        onKeyDown={(e) => {
          if (!disabled && (e.key === 'Enter' || e.key === ' ')) {
            e.preventDefault();
            onClick?.();
          }
        }}
      >
        {icon && <span className="button-icon" aria-hidden="true">{icon}</span>} {/* Hide icon from screen readers */}
        <span className="button-label">{label}</span>
      </motion.a>
    );
  };

export default FullIconButton;
