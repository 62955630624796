import GalleryImage from "../../assets/ProjectCovers/GalleryCreatorCover.png";
import TempImage from "../../assets/ProjectCovers/ESP-Server-Cover.png";
import HighwayImage from "../../assets/ProjectCovers/Highway-Racers_Cover.png";
import DogpitImage from "../../assets/ProjectCovers/dog.svg"

import { FaGithub, FaPlay, FaGlobe } from 'react-icons/fa';


export const projects = [
    {
      id: 1,
      imageSrc: GalleryImage,
      title: "Gallery Creator",
      subtitle: "C#",
      description: "GalleryCreator, a .NET solution designed for the management and processing of dynamic photo galleries. This application is fine-tuned for integration with Angular-based projects, offering features for image handling.",
      date: "Completed: January 2024",
      link: "https://github.com/DaveC6662/GalleryCreator",
      linkLabel: "View Repository",
      icon: FaGithub,
    },
    {
      id: 2,
      imageSrc: TempImage,
      title: "ESP32 Temperature Server",
      subtitle: "C / C++ / JavaScript",
      description: "The ESP32 Temperature Monitoring Server is a solution designed to capture and display real-time temperature readings using the DS18B20 digital temperature sensor. This project uses the ESP32's Wi-Fi capabilities to create a responsive web server that displays these temperature readings in a user-friendly format as well as sending webhook notifications.",
      date: "Completed: January 2024",
      linkLabel: "View Repository",
      link: "https://github.com/DaveC6662/ESP32-Temperature-Server",
      icon: FaGithub
    },
    /*
    {
      id: 3,
      imageSrc: HighwayImage,
      title: "Highway Racers",
      subtitle: "JavaScript / LittleJS Engine",
      description: "In 'Highway Racers' you take control of a high-performance car, speeding down a two lane highway filled with various obstacles and challenges. The objective is simple: drive as far as possible while avoiding collisions and earning points for your distance traveled.",
      date: "Completed: June 2024",
      link: "/HighwayRacers",
      linkLabel: "Play now!",
      icon: FaPlay
    },
    */
    {
        id: 4,
        imageSrc: DogpitImage, 
        title: "Dog Pit",
        subtitle: "React / SCSS / JavaScript",
        description: "dogpit.ca is a responsive and dynamic web platform created for Londons DIY venue The Dog Pit",
        date: "Launched: September 2024",
        link: "https://dogpit.ca",
        linkLabel: "Visit Site",
        icon: FaGlobe
      },
  ];