import React, { useState } from 'react';
import "./DesignPage.scss"
import { FaHome, FaUser, FaEnvelope, FaEraser, FaLandmark, FaSadCry } from 'react-icons/fa';
import SmIconButton from '../../Components/UI Components/Buttons/SmallIconButton/SmallIconButton';
import Button from '../../Components/UI Components/Buttons/Button/Button';
import Toggle from '../../Components/UI Components/Toggle/ToggleSwitch/Toggle';
import ThemeToggle from '../../Components/UI Components/Toggle/ThemeToggle/ThemeToggle';
import SimpleCard from '../../Components/Cards/SimpleCard/SimpleCard';
import AlbumCard from '../../Components/Cards/AlbumCard/AlbumCard';
import VideoModal from '../../Components/Modals/VideoModal/VideoModal';
import ImageModal from '../../Components/Modals/ImageModal/ImageModal';
import FullIconButton from '../../Components/UI Components/Buttons/FullIconButton/fullIconBtn';
import { FaApple, FaSpotify, FaBandcamp, FaYoutube } from 'react-icons/fa';

const Design: React.FC = () => {
  const [isToggled, setIsToggled] = useState(false);
  const handleToggle = () => setIsToggled(!isToggled);
 const album =
  {
    imageUrl: 'https://picsum.photos/300/200',
    title: "Sample Album",
    subtitle: "June 2024",
    content: [
        "Written by: Example Artist",
        "Mixed by: Example Producer",
        "Mastered by: Example Engineer"
    ],
    links: [
        {   icon: FaApple, tooltip: "Apple Music" },
        { icon: FaSpotify, tooltip: "Spotify" },
        {  icon: FaBandcamp, tooltip: "Bandcamp" },
        { icon: FaYoutube, tooltip: "YouTube Music" }
    ]
}
 

  return (
    <div className='design-container'>
      <h1>UI Elements & Styling</h1>
      <p>Developed Using TSX, SCSS and Framer Motion for Consistent Design</p>
      <section className='design-section'>
        <h2>Buttons</h2>
        <div className='icon-buttons-container'>
          <Button theme="primary" label="Primary" />
          <Button theme="secondary" label="Secondary" />
          <Button theme="warning" label="Warning" />
          <Button theme="error" label="Error" />
          <Button theme="success" label="Success" />
          <Button theme="success" label="Disabled" disabled={true}/>
        </div>
      </section>
      <section className='design-section'>
        <h2>Icon Buttons</h2>
        <div className='icon-buttons-container'>
          <FullIconButton theme="primary" label="Primary" icon={<FaHome/>} />
          <FullIconButton theme="secondary" label="Secondary" icon={<FaUser/>} />
          <FullIconButton theme="warning" label="Warning" icon={<FaEnvelope/>} />
          <FullIconButton theme="error" label="Error" icon={<FaEraser/>} />
          <FullIconButton theme="success" label="Success" icon={<FaLandmark/>} />
          <FullIconButton theme="success" label="Disabled" icon={<FaSadCry/>} disabled={true} />
        </div>
      </section>
      <section className='design-section'>
        <h2>Small Icon Buttons</h2>
        <div className='icon-buttons-container'>
          <SmIconButton icon={FaHome} theme="primary" />
          <SmIconButton icon={FaUser} theme="secondary"/>
          <SmIconButton icon={FaEnvelope} theme="warning"/>
          <SmIconButton icon={FaEraser} theme="error"/>
          <SmIconButton icon={FaLandmark} theme="success"/>
          <SmIconButton icon={FaSadCry} theme="success" disabled={true}/>
        </div>
      </section>
      <section className='design-section'>
        <h2>Toggle Switch</h2>
        <div className='toggle-container'>
        <p>Simple Toggle</p>
        <Toggle isToggled={isToggled} onToggle={handleToggle} />
        <p>Theme Toggle</p>
        <ThemeToggle />
        </div>
      </section>
      <section className='design-section'>
        <h2>Cards</h2>
        <div className='cards-container'>
          <SimpleCard
            theme="primary"
            cardHeader="Sample Card"
            cardContent="This is a sample card."
            backgroundImage="https://picsum.photos/300/200"
          />
          <AlbumCard
                        imageUrl={album.imageUrl}
                        title={album.title}
                        releaseDate={album.subtitle}
                        credits={album.content}
                        listenLinks={album.links}
                    />
            
        </div>
      </section>
      <section className='design-section'>
        <h2>Video Modal</h2>
        <div className='modal-container'>
        <VideoModal
              videoID={'dQw4w9WgXcQ'}
              videoTitle={'Sample Video'}
            />
        </div>
        <h2>Image Modal</h2>
        <div className='image-modal-container'>
        <ImageModal
              className={"image-modal"}
              imageUrl={'https://picsum.photos/600/600'}
              thumbnailUrl={'https://picsum.photos/600/600'}
            />
        </div>
      </section>
    </div>
  );
};

export default Design;
