import React from 'react';
import './Terms.scss';

const Terms: React.FC = () => {
    return (
        <div className="terms-container">
          <h1>Terms of Service</h1>
    
          <h2>Effective Date: October 1st, 2024</h2>
    
          <p>Welcome to Davin Chiupka's Site ("the Site"). By accessing or using this Site, you agree to comply with and be bound by the following terms and conditions ("Terms of Service"). Please review these terms carefully. If you do not agree with these Terms of Service, you should not use this Site.</p>
    
          <h3>1. Acceptance of Terms</h3>
          <p>By accessing and using the Site, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service, as well as any additional terms and policies referenced herein or available by hyperlink.</p>
    
          <h3>2. Use of the Site</h3>
          <p>You agree to use the Site only for lawful purposes and in a manner that does not infringe on the rights of, restrict, or inhibit the use and enjoyment of the Site by any third party.</p>
    
          <ul>
            <li>You must not use the Site for any illegal or unauthorized purpose.</li>
            <li>You agree not to attempt to access any restricted areas of the Site without proper authorization.</li>
            <li>You are responsible for all activity conducted under your access to the Site.</li>
          </ul>
    
          <h3>3. Intellectual Property</h3>
          <p>All content on the Site, including text, images, graphics, and code, is owned by or licensed to Davin Chiupka and is protected by intellectual property laws. You may not reproduce, distribute, or use any content without explicit written permission.</p>
    
          <h3>4. Third-Party Links</h3>
          <p>The Site may contain links to third-party websites or services that are not owned or controlled by Davin Chiupka. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party sites or services.</p>
    
          <h3>5. Disclaimer of Warranties</h3>
          <p>The Site is provided on an "as-is" and "as-available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of the Site or the information, content, or materials included on the Site. You expressly agree that your use of the Site is at your sole risk.</p>
    
          <h3>6. Limitation of Liability</h3>
          <p>In no event shall Davin Chiupka be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Site or its content.</p>
    
          <h3>7. Changes to These Terms</h3>
          <p>We reserve the right to modify or replace these Terms of Service at any time. Any changes will be posted on this page, and the "Effective Date" will be updated. Your continued use of the Site after any changes constitutes your acceptance of the new Terms of Service.</p>
    
          <h3>8. Governing Law</h3>
          <p>These Terms of Service shall be governed by and construed in accordance with the laws of Ontario, Canada without regard to its conflict of law provisions.</p>
    
          <h3>9. Contact Information</h3>
          <p>If you have any questions about these Terms of Service, please contact us at:</p>
          <ul>
            <li>Davin Chiupka</li>
            <li>davinchiupka@gmail.com</li>
          </ul>
    
          <p>By using the Site, you agree to the terms outlined in this Terms of Service agreement.</p>
        </div>
      );
    };

export default Terms;
