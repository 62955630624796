import musicImage from "../../assets/img/vinyl-image.jpg";
import photoImage from "../../assets/img/camera-image.jpg";
import codeImage from "../../assets/img/code-image.jpg";

export const sections = [
  {
    title: "Music",
    link: "/music",
    backgroundImage: musicImage,
    description: "Dive into my music projects, spanning genres from rock to alternative folk.",
  },
  {
    title: "Photography",
    link: "/photography",
    backgroundImage: photoImage,
    description: "Explore my photography portfolio, showcasing everything from macro to concert photography.",
  },
  {
    title: "Code",
    link: "/code",
    backgroundImage: codeImage,
    description: "Discover a range of passion projects from over the years.",
  },
];