import React from "react";
import "./Footer.scss";

import { Link } from "react-router-dom";
import { socialLinks } from "../../Utils/resources/SocialsArray";
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';

import SmIconButton from "../UI Components/Buttons/SmallIconButton/SmallIconButton";
import ThemeToggle from "../UI Components/Toggle/ThemeToggle/ThemeToggle";

interface FooterProps {
  theme?: 'primary' | 'secondary' | string;
}

const Footer = ({ theme = 'primary' }: FooterProps) => {
  return (
    <footer className={`footer-container ${theme}`}>
      <div className="footer-content">

        <div className="footer-left">
          <p>© Davin Chiupka</p>
        </div>
        
        <div className="footer-links">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-of-service">Terms Of Service</Link>
          <Link to="/site-map">Site Map</Link>
        </div>

        <div className="theme-toggle-container">
          <ThemeToggle />
        </div>
        
        <div className="footer-social">
          {socialLinks.map((social, index) => (
            <SmIconButton
              key={index}
              icon={social.icon}
              label={social.title}
              onClick={() => {
                logEvent(analytics, 'social_link_click', {
                  platform: social.title,
                  url: social.href,
                });
        
                window.open(social.href, '_blank', 'noopener,noreferrer');
              }}
            />
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
