import React, { useState } from 'react';
import SmIconButton from '../../UI Components/Buttons/SmallIconButton/SmallIconButton';
import { FaTimes } from 'react-icons/fa';
import './ImageModal.scss';

interface ImageModalProps {
  imageUrl: string;
  imageTitle?: string;
  thumbnailUrl?: string;
  className?: string;
}

const ImageModal: React.FC<ImageModalProps> = ({ imageUrl, imageTitle, thumbnailUrl, className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasError, setHasError] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleImageError = () => {
    setHasError(true);
  };

  if (hasError) return null;

  return (
    <div className={`${className}`}>

      <img
              src={thumbnailUrl}
              alt=""
              className="modal-image"
              loading="lazy"
              onClick={openModal}
              onError={handleImageError}
        />

      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button
              title='close button'
              className="close-button"
              tabIndex={0}
              type='button'
              aria-label={'close button'}
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  closeModal();
                }
              }}
            >
              <FaTimes />
            </button>
            <img
              src={thumbnailUrl}
              alt=""
              className="modal-image"
              loading="lazy"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageModal;
