import React from 'react';
import './SiteMap.scss';

const SiteMap: React.FC = () => {
  return (
    <div className="sitemap-container">
      <h1>Site Map</h1>

      <div className="sitemap-section">
        <h2>Main Pages</h2>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/Code">Code Projects</a></li>
          <li><a href="/Design">Design</a></li>
          <li><a href="/Music">Music</a></li>
        </ul>
      </div>

      <div className="sitemap-section">
        <h2>Legal and Information</h2>
        <ul>
          <li><a href="/privacy-policy">Privacy Policy</a></li>
          <li><a href="/terms-of-service">Terms of Service</a></li>
          <li><a href="/sitemap">Site Map</a></li>
        </ul>
      </div>

      <div className="sitemap-section">
        <h2>Utility Pages</h2>
        <ul>
          <li><a href="/404">404 - Page Not Found</a></li>
        </ul>
      </div>
    </div>
  );
};

export default SiteMap;
