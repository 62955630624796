import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './Components/Footer/Footer';
import NavBar from './Components/NavBar/NavBar';
import ThemeProvider from './Components/Util Components/ThemeProvider';
import AnimatedRoutes from './Components/Util Components/AnimatedRoutes';
import ScrollToTop from './Hooks/scrollToTop';
import { analytics } from './firebase'; 
import { logEvent } from 'firebase/analytics';

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop/>
      <ThemeProvider>
        <AppContent />
      </ThemeProvider>
    </Router>
  );
};


const AppContent: React.FC = () => {
  useEffect(() => {
    logEvent(analytics, 'page loaded');
  }, []);

  return (
    <>
      <NavBar />
      <AnimatedRoutes />
      <Footer />
    </>
  );
};

export default App;
