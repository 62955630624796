import React from 'react';
import { motion } from 'framer-motion';
import './Toggle.scss';

interface ToggleProps {
  isToggled: boolean;
  onToggle: () => void;
  label?: string; 
}

const Toggle: React.FC<ToggleProps> = ({ isToggled, onToggle, label }) => {
  return (
    <div className="toggle-container">
      <motion.div
        className={`toggle ${isToggled ? 'active' : ''}`}
        onClick={onToggle}
        initial={false}
        animate={{ backgroundColor: isToggled ? 'var(--link-hover-color-primary)' : 'var(--primary-color)' }}
        transition={{ duration: 0.2, ease: 'linear' }}
        role="switch" 
        aria-checked={isToggled} 
        aria-label={label} 
        tabIndex={0} 
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onToggle();
          }
        }}
      >
        <motion.div
          layout
          className="toggle-knob"
          animate={{ x: isToggled ? 29 : 0 }}
          transition={{ type: 'spring', stiffness: 400, damping: 17 }}
        />
      </motion.div>
    </div>
  );
};

export default Toggle;
