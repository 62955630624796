import { IconType } from 'react-icons';
import { FaFacebook, FaLinkedin, FaTwitter, FaApple, FaSpotify, FaBandcamp, FaGithub, FaYoutube } from 'react-icons/fa';

interface SocialLink {
  title: string | undefined;
  icon: IconType;
  href: string;
}

export const socialLinks: SocialLink[] = [
  {
    title: "Facebook",
    href: "https://www.facebook.com/davinchiupkacreative/",
    icon: FaFacebook,
  },
  {
    title: "LinkedIn",
    href: "https://ca.linkedin.com/in/davinchiupka",
    icon: FaLinkedin,
  },
  {
    title: "Twitter",
    href: "https://twitter.com/DChiupka86426",
    icon: FaTwitter,
  },
  {
    title: "Apple Music",
    href: "https://music.apple.com/ca/artist/davin-chiupka/892607979",
    icon: FaApple,
  },
  {
    title: "Spotify",
    href: "https://open.spotify.com/artist/6ThacdQ3ZTZfRWSJAlPOqO?si=ilUcUXM3SbOrF31o6Xmz0w",
    icon: FaSpotify,
  },
  {
    title: "Bandcamp",
    href: "https://davinchiupka.bandcamp.com/music",
    icon: FaBandcamp,
  },
  {
    title: "Github",
    href: "https://github.com/DaveC6662",
    icon: FaGithub,
  },
  {
    title: "Youtube",
    href: "https://www.youtube.com/@davinchiupka6034",
    icon: FaYoutube,
  },
];
