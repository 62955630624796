import React from 'react';
import './CodePage.scss';
import heroImage from "../../assets/img/code-hero.jpg"
import { projects } from "../../Utils/resources/codeProjects";
import ProjectCard from '../../Components/Cards/ProjectCard/ProjectCard';
import { motion } from "framer-motion";
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';

const CodePage: React.FC = () => {
  return (
    <div className="code-page-container">
      <motion.div
        className="hero-section"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <motion.div
          className="hero-content"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
                <h1>My Code Projects</h1>
        </motion.div>
        <div className="overlay"></div>
      </motion.div>
      <div className="projects-container">
        {projects.map((project) => (
          <ProjectCard
            key={project.id}
            imageSrc={project.imageSrc}
            title={project.title}
            subtitle={project.subtitle}
            description={project.description}
            date={project.date}
            link={project.link}
            linkLabel={project.linkLabel}
            linkIcon={project.icon}
            onClick={() => {
              logEvent(analytics, 'project_link_click', {
                project_title: project.title,
                project_link: project.link,
              });
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CodePage;
