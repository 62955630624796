import React from 'react';
import { motion } from 'framer-motion';
import { FaSun, FaMoon } from 'react-icons/fa';
import './ThemeToggle.scss';

import Toggle from '../ToggleSwitch/Toggle';
import { useTheme } from '../../../Util Components/ThemeProvider';

const ThemeToggle = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <div className="theme-toggle">
      <motion.div
        key={theme}
        initial={{ opacity: 0, scale: 0.8, rotate: -45 }}
        animate={{ opacity: 1, scale: 1, rotate: 0 }}
        exit={{ opacity: 0, scale: 0.8, rotate: 45 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className="theme-icon-container"
      >
        {theme === 'dark' ? (
          <FaMoon className="theme-icon" />
        ) : (
          <FaSun className="theme-icon" />
        )}
      </motion.div>
      <Toggle isToggled={theme === 'dark'} onToggle={toggleTheme} />
    </div>
  );
};

export default ThemeToggle;
