import React, { useState, useEffect } from 'react';
import './ImagePage.scss';
import data from '../../assets/data.json';
import Pager from '../../Components/Pager/Pager';
import { motion, AnimatePresence } from "framer-motion";
import heroImage from "../../assets/HeroImages/HangingTreeMoss.jpg"
import ImageModal from '../../Components/Modals/ImageModal/ImageModal';

interface Resolution {
  Path: string;
  Width: number;
  Height: number;
}

interface Resolutions {
  PreviewS: Resolution;
  PreviewM: Resolution;
  PreviewL: Resolution;
}

interface ImageData {
  Resolutions: Resolutions;
  Alt?: string | null;
}

const Image: React.FC = () => {
  const [screenSize, setScreenSize] = useState<'lg' | 'md' | 'sm'>('lg');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const getImageResolution = (resolutions: Resolutions, screenSize: 'lg' | 'md' | 'sm') => {
    switch (screenSize) {
      case 'lg':
        return resolutions.PreviewL.Path;
      case 'md':
        return resolutions.PreviewM.Path;
      case 'sm':
      default:
        return resolutions.PreviewS.Path;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) setScreenSize('lg');
      else if (window.innerWidth > 768) setScreenSize('md');
      else setScreenSize('sm');
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="image-container">
      <motion.div
        className="hero-section"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <motion.div
          className="hero-content"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
                <h1>Welcome to the World Through My Lens</h1>
        </motion.div>
        <div className="overlay"></div>
      </motion.div>
      <Pager
        className="pager-container"
        totalItems={data.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
      <AnimatePresence mode='wait'>
      <motion.div
          key={currentPage}
          className="image-gallery"
          initial={{ opacity: 0, width: '100%' }}
          animate={{ opacity: 1, width: '100%' }}
          exit={{ opacity: 0, x: -50 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
        {currentItems.map((imageData: ImageData, index) => {
          const resolutionPath = getImageResolution(imageData.Resolutions, screenSize);

          return (
            <ImageModal
              className={"image-modal"}
              key={index}
              imageUrl={resolutionPath}
              thumbnailUrl={resolutionPath}
            />
          );
        })}
      </motion.div>
      </AnimatePresence>
      <Pager
        className="pager-container"
        totalItems={data.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};
export default Image;
