import img1 from "../../../src/assets/HeroImages/AbandonedCabin.jpg"
import img2 from "../../../src/assets/HeroImages/LakeSuperiorRocks.jpg"
import img3 from "../../../src/assets/HeroImages/HangingTreeMoss.jpg"
import img4 from "../../../src/assets/HeroImages/BabyBlueBridge.jpg"
import img5 from "../../../src/assets/HeroImages/Foggytrees.jpg"
import img6 from "../../../src/assets/HeroImages/IceBlocks.jpg"
import img7 from "../../../src/assets/HeroImages/OddFellowsHall.jpg"
import img8 from "../../../src/assets/HeroImages/SunsetSilhouet.jpg"

export const heroImages = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
  ];