import React from 'react';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import { FaHome, FaMusic, FaCamera, FaCode, FaPaintBrush } from 'react-icons/fa';
import './NavBar.scss';

interface NavItem {
    icon: React.ReactNode;
    label: string;
    path: string;
  }

const NavBar: React.FC = () => {
    const navItems: NavItem[] = [
        { icon: <FaHome />, label: 'Home', path: '/' },
        { icon: <FaMusic />, label: 'Music', path: '/Music' },
        { icon: <FaCamera />, label: 'Images', path: '/Photography' },
        { icon: <FaCode />, label: 'Code', path: '/Code' },
        { icon: <FaPaintBrush/>, label: 'Design', path: '/Design' },
      ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  return (
    <motion.div
      className='nav-container'
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {navItems.map((item, index) => (
                <motion.div 
                    key={index}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <NavLink
                        to={item.path}
                        className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}
                        title={item.label}
                    >
                        {({ isActive }) => (
                            <>
                                <motion.div
                                    className="icon-container"
                                    animate={{ fontSize: isActive ? '1.5em' : '1em' }}
                                    transition={{ type: 'spring', stiffness: 300, damping: 20 }}
                                >
                                    {item.icon}
                                </motion.div>
                                {!isActive && <span className="label">{item.label}</span>}
                            </>
                        )}
                    </NavLink>
                </motion.div>
            ))}
    </motion.div>
  );
};

export default NavBar;
