import React, {useEffect} from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// Import pages
import Home from "../../Pages/HomePage/HomePage";
import Design from "../../Pages/DesignPage/DesignPage";
import Music from "../../Pages/MusicPage/MusicPage";
import Code from "../../Pages/CodePage/CodePage";
import Image from "../../Pages/ImagePage/ImagePage";
import NotFound from "../../Pages/PageNotFound/PageNotFound";
import PrivacyPolicy from "../../Pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "../../Pages/TermsPage/Terms";
import SiteMap from "../../Pages/SiteMap/SiteMap";
//import HighwayRacers from "../../Pages/HighwayRacers/HighwayRacers";

import { analytics } from "../../firebase"; 
import { logEvent } from "firebase/analytics";


const AnimatedRoutes: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_location: window.location.href,
    });
  }, [location]);

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home/>}/>
          <Route path="/Design" element={<Design/>}/>
          <Route path="/Music" element={<Music/>}/>
          <Route path="/Code" element={<Code/>}/>
          <Route path="/Photography" element={<Image/>}/>
          <Route path="/Design" element={<Design/>}/>
          {/*<Route path="/HighwayRacers" element={<HighwayRacers/>}/>*/}
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/terms-of-service" element={<Terms/>}/>
          <Route path="/site-map" element={<SiteMap/>}/>
          <Route path="*" element={<NotFound/>}/>
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
