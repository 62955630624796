import React from 'react';
import './PrivacyPolicy.scss';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className='privacy-container'>
        <h1>Privacy Policy</h1>

<h2>Effective Date: October 1st 2024</h2>

<p>Welcome to Davin Chiupka's Site ("the Site"). Your privacy is very important to us,
     and we are committed to protecting your personal information. This Privacy Policy outlines what 
     information we collect, how we use it, and your rights regarding your personal data.</p>

<h3>1. Information We Collect</h3>
<p>We collect and use the following information when you visit the Site:</p>

<h4>1.1 Automatically Collected Information</h4>
<p>When you visit the Site, certain information is automatically collected by Google Analytics, such as:</p>
<ul>
<li>IP Address</li>
<li>Browser Type and Version</li>
<li>Operating System</li>
<li>Pages Visited and Time Spent</li>
<li>Referral Source (how you arrived at the Site)</li>
</ul>

This information is used to understand user behavior, improve the Site, and analyze traffic trends.

<h3>2. How We Use Your Information</h3>
<p>We may use the information collected in the following ways:</p>
<ul>
<li>To improve the Site's functionality and user experience.</li>
<li>To analyze Site traffic and usage through Google Analytics.</li>
<li>To respond to your inquiries or communications.</li>
</ul>

<h3>3. Cookies and Tracking Technologies</h3>
<p>We use cookies and similar tracking technologies to track user 
    activity on the Site and collect certain information. Cookies are small text files stored on
     your device that help us analyze how the Site is used.</p>

<p>You may choose to disable cookies through your browser settings, but please note 
    that some features of the Site may not function properly without cookies.</p>

<h3>4. Third-Party Services</h3>
<p>We may use third-party services, such as:</p>

<ul>
<li>Google Analytics: To collect, monitor, and analyze traffic and usage on the Site. For more information about how Google Analytics 
    collects and processes data, please visit Google's Privacy & Terms.</li>
<li>Embedded Content: The Site may include embedded content (e.g., videos, social media posts) from other websites.
Embedded content from other websites behaves in the same way as if you visited the other website, which may collect data about you or use cookies.</li>
</ul>

<h3>5. Data Security</h3>
<p>We take reasonable precautions to protect your personal 
    information and maintain its security. However, please 
    note that no method of transmission over the internet or method of electronic
     storage is 100% secure.</p>

<h3>6. Your Data Protection Rights</h3>
<p>Depending on your location and applicable laws, you may have the following rights:</p>
<ul>
<li>Right to Access: You have the right to request copies of your personal information.</li>
<li>Right to Rectification: You have the right to request that we correct any information you believe is inaccurate or complete any information that is incomplete.</li>
<li>Right to Erasure: You have the right to request that we erase your personal data under certain conditions.</li>
<li>Right to Object to Processing: You have the right to object to our processing of your personal data.</li>
</ul>

<p>If you wish to exercise any of these rights, please contact us at davinchiupka@gmail.com.</p>

<h3>7. Changes to This Privacy Policy</h3>
<p>We may update this Privacy Policy from time to time. Changes will be posted on this page, 
    and the "Effective Date" will be updated. Your continued use of the Site after any changes are made 
    constitutes your acceptance of the new Privacy Policy.</p>

<h3>8. Contact Us</h3>
<p>If you have any questions about this Privacy Policy, please contact us at:</p>
<ul>
<li>Davin Chiupka</li>
<li>davinchiupka@gmail.com</li>
</ul>
<p>By using the Site, you agree to the terms outlined in this Privacy Policy.</p>
</div>
  );
};

export default PrivacyPolicy;
