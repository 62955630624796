import React from "react";
import { motion } from "framer-motion";
import "./SimpleCard.scss";

interface CardProps {
  theme: "primary" | "secondary";
  cardHeader?: string;
  cardContent?: string;
  backgroundImage?: string;
  onClick?: () => void;
  className?: string;
}

const SimpleCard: React.FC<CardProps> = ({ theme, cardHeader, cardContent, backgroundImage, onClick, className }) => {
  return (
    <motion.div
      className={`card-container ${theme} ${className}`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.05, y: -8 }}
      whileTap={{ scale: 0.97 }} 
      transition={{ duration: 0.4, ease: "easeOut" }}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
    >
      <div className="card-content">
        <h1 className="card-header">{cardHeader || ""}</h1>
        <p className="card-text">{cardContent || ""}</p>
      </div>
    </motion.div>
  );
};

export default SimpleCard;
