import React from 'react';
import "./MusicPage.scss"

import heroImage from "../../assets/img/music-hero.jpg"
import { albums } from '../../Utils/resources/albumData';
import { ytVideos } from '../../Utils/resources/ytVideos';

import { motion } from "framer-motion";

import AlbumCard from '../../Components/Cards/AlbumCard/AlbumCard';
import VideoModal from '../../Components/Modals/VideoModal/VideoModal';

const Music: React.FC = () => {
  return (
    <div className='music-container'>
      <motion.div
        className="hero-section"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <motion.div
          className="hero-content"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <h1 className="hero-title">
            Dive Into <span className="highlight">My Soundscape</span>
          </h1>
        </motion.div>
        <div className="overlay"></div>
      </motion.div>
      <div className='content-container'>
        <h1>Discography</h1>
        <div className='album-cards'>
          {albums.map((album, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2, duration: 0.5 }} 
            >
              <AlbumCard
                imageUrl={album.imageUrl}
                title={album.title}
                releaseDate={album.subtitle}
                credits={album.content}
                listenLinks={album.links}
              />
            </motion.div>
          ))}
        </div>
        <h1>Videos</h1>
        <div className='video-container'>
          {ytVideos.map((video, index) => (
            <VideoModal
              key={index}
              videoID={video.videoID}
              videoTitle={video.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Music;