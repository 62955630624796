export const ytVideos = [
    {
        title: "Find Myself Backyard Session",
        videoID: "43ZRqWlsE3g"
    },
    {
        title: "The Ocean Bedroom Session",
        videoID: "80tqcY1n9pE"
    },
    {
        title: "The North Lyric Video",
        videoID: "aJ1nhXqiqqg"
    }
]