import React from 'react';
import { motion } from 'framer-motion';
import './SmallIconButton.scss';

interface SmIconButtonProps {
  icon: React.ComponentType;
  theme?: 'primary' | 'secondary' | 'warning' | 'error' | 'success' | string;
  href?: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  label?: string;
}

const SmIconButton: React.FC<SmIconButtonProps> = ({
  icon: Icon,
  theme = 'primary',
  href,
  onClick,
  className = '',
  disabled = false,
  label
}) => {
  return (
    <motion.a
      href={disabled ? undefined : href}
      className={`sm-icon-button ${theme} ${className} ${disabled ? 'disabled' : ''}`}
      target={href && !disabled ? "_blank" : undefined}
      rel={href && !disabled ? "noopener noreferrer" : undefined}
      whileHover={!disabled ? { scale: 1.05 } : {}}
      whileTap={!disabled ? { scale: 0.95 } : {}}
      transition={{ type: 'spring', stiffness: 300, damping: 20 }}
      onClick={(e) => {
        if (!disabled && onClick) {
          e.preventDefault();
          onClick();
        }
      }}
      onKeyDown={(e) => {
        if (!disabled && (e.key === 'Enter' || e.key === ' ')) {
          e.preventDefault();
          onClick?.();
        }
      }}
      aria-label={label}
      role="button"
      aria-disabled={disabled}
      tabIndex={0}
    >
      <Icon />
    </motion.a>
  );
};

export default SmIconButton;
